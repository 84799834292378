import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

const styles = css`
  .wrap {
    position: relative;
    padding: 1rem;
    max-width: 700px;
    color: rgb(10, 21, 48);
    margin: 2rem 1rem;
    z-index: 1;

    @media (min-width: 40rem) {
      margin: 0rem auto;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 99%;
        height: 99%;
        border-radius: 1rem;
        background: linear-gradient(to right bottom, rgb(114, 198, 239), rgb(0, 78, 143));
        transform: rotate(2deg);
        transform-origin: 40% 70%;
        box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      }
    }
  }
  .bg {
    position: relative;
    z-index: 1;

    @media (min-width: 40rem) {
      box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      background: #fff;
      border-radius: 1rem;
      padding: 3rem 3rem 2rem;
      @media (forced-colors: active) {
        border: 1px solid;
      }
    }
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Convince your manager to get a Polypane subscription"
          description="Copy and paste this letter and send it to your manager if you need to convince them to get a Polypane subscription."
          keywords={[]}
          pathname={this.props.location.pathname}
        />
        <PageHeader>
          <StaticImage
            src="../../assets/images/icon.png"
            alt=""
            objectFit="contain"
            placeholder="none"
            width={150}
            formats={['PNG']}
          />
          <h1>Why we need Polypane</h1>
          <h2>Copy and paste this letter and send it to your manager.</h2>
        </PageHeader>

        <div className="page" style={{ maxWidth: '60rem' }}>
          <div className={styles.wrap}>
            <div className={[styles.bg, 'childPadding'].join(' ')}>
              <p>Hey [manager],</p>

              <p>
                I've been trying out this game-changing devtool and I'd like to request a license for it. It's called{' '}
                <a href="https://polypane.app">Polypane</a> and here's why it would be beneficial for us:
              </p>
              <ul>
                <li>It's a desktop browser based on Chrome but made for developers.</li>
                <li>It lets you test multiple screen sizes simultaneously, saving time and effort.</li>
                <li>
                  It offers dozens of in-app accessibility checks and suggestions, helping us build high-quality and
                  accessible sites.
                </li>
                <li>Even better, the Polypane team is responsive to feedback and releases new versions each month.</li>
              </ul>

              <p>
                A subscription only costs $108 per year, and it has already saved me at least an hour in the
                two-week trial. I'm confident this will be a great investment.
              </p>

              <p>
                Their <a href="https://polypane.app/calculator/">online calculator</a> lets you estimate how quickly
                you'd break even. Let me know if you need any info from me. If more team members join, we can get a
                discounted team plan.
              </p>

              <p>
                To set up a subscription for me, visit{' '}
                <a href="https://polypane.app/pricing/">https://polypane.app/pricing/</a>, finance can be listed as the
                billing email. I can also do it - with your approval, of course!
              </p>

              <p>
                If you have any questions, let me know! I'd be happy to walk you through the app. Otherwise, I'd love to
                get a subscription as soon as possible.
              </p>

              <p>
                Thanks!
                <br />
                <em>[Your name]</em>
              </p>

              {/* <p>Hey [manager],</p>

              <p>
                As you know, building responsive, accessible, and high-quality websites takes care, effort, and time.
                For the past two weeks, I've been trying out this devtool, <a href="https://polypane.app">Polypane</a>,
                and it's a game changer.
              </p>

              <p>
                Polypane is a desktop browser, like Chrome, made just for developers. It's great because it enables me
                to test multiple screen sizes at once (instead of one by one like I currently have to), and has dozens
                of in-app accessibility checks and suggestions to help me build the best site possible. Even better, the
                Polypane team is responsive to feedback and releases new versions each month.
              </p>

              <p>
                A subscription only costs $108 per year, and it has already saved me at least an hour in the
                two-week trial. I'm confident this will be a great investment.
              </p>

              <p>
                Their <a href="https://polypane.app/calculator/">online calculator</a> lets you estimate how quickly
                you'd break even. Let me know if you need any info from me. Also, if more people on our team start using
                Polypane, we can get a discounted team plan.
              </p>

              <p>
                You can easily set up a subscription for me at{' '}
                <a href="https://polypane.app/pricing/">https://polypane.app/pricing/</a>, and finance can be listed
                under the billing email. I can also do it - with your approval, of course!
              </p>

              <p>
                If you have any questions, let me know! I'd be happy to walk you through the app. Otherwise, I'd love to
                get a subscription as soon as possible.
              </p>

              <p>Looking forward to hearing back from you soon!</p>

              <p>
                Thanks!
                <br />
                <em>[Your name]</em>
              </p> */}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Page;
